import React, { useState } from 'react';

import loadable from '@loadable/component';
import { returnResizedImageURL } from '@accrosoft-ltd/accropress-websites-components/dist/esm/imgUtils';

const WeducNotices = loadable(() =>
  import('../../components/WeducNotices'),
);

const WigLoader = loadable(() =>
  import('@accrosoft-ltd/accropress-websites-components/dist/esm/wigLoader'),
);

const HeroInnerContent = (props) => {
  const [sidebarLinksActive, setSidebarLinksActive] = useState(null);

  const pluginOptions = props.pluginOptions;

  return (
    <div className="HeroInnerContent">
      {pluginOptions.quickLinksType === 'normal' &&
        props.contentDataData[0] &&
        props.contentDataData[0].children[4] &&
        props.contentDataData[0].children[4].children && (
          <div className="bannerSidebar">
            {props.contentDataData[0].children[4].children.map((col, index) => {
              return (
                <div className="bannerSidebar_Item">
                  <div
                    className="bannerSidebar_Item_Opener bounce"
                    onClick={() =>
                      setSidebarLinksActive(
                        sidebarLinksActive !== index ? index : null,
                      )
                    }
                  >
                    <div className="bannerSidebar_Item_Opener_Content">
                      <div className="bannerSidebar_Item_Opener_Icon">
                        <span className={col[0].value}></span>
                      </div>

                      <div>{col[1].value}</div>
                    </div>
                  </div>
                  {sidebarLinksActive === index && (
                    <div className="bannerSidebar_Item_Content">
                      <button
                        className="close"
                        onClick={() =>
                          setSidebarLinksActive(
                            sidebarLinksActive !== index ? index : null,
                          )
                        }
                      >
                        <span className="fal fa-times"></span>
                      </button>
                      <WigLoader
                        weducWebsitesID={props.config.weducWebsitesID}
                        weducContainer={props.config.weducContainerID}
                        weducEntity={props.config.weducEntityID}
                        contentDataData={props.contentDataData}
                        contentMetaData={props.contentMetaData}
                        contentDataArray="0"
                        contentDataChild="4"
                        customDataArray={col[2].value}
                        AccroPressProdEndpoint={props.config.apiEndpoint}
                        AccroPressLocalEndpoint={props.config.apiEndpoint}
                        integratorID={props.config.integratorID}
                        integratorAccessToken={
                          props.config.integratorAccessToken
                        }
                        imageFormatting={
                          process.env.IMAGE_FORMATTING_SECRET ||
                          process.env.GATSBY_IMAGE_FORMATTING_SECRET
                        }
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      {props &&
        props.contentDataData &&
        props.setNoticesMainLoaded &&
        props.NoticeTemplate &&
        props.toggleNoticesBanner &&
        props.config &&
        props.pluginOptions && (
          <>
            {!props.pluginOptions.useHeroSlider && (
              <div className="banner home-banner">
                {!props.pluginOptions.hideScroller && (
                  <div className="arrow-container">
                    <div className="arrow-down">
                      <div>
                        <button
                          activeclass="active"
                          className="btn scroll"
                          type="submit"
                          value="Scroll Here"
                          onClick={() =>
                            props.scrollToContent('scrollHere', -140)
                          }
                        >
                          <div>Scroll</div>
                          <span className="fal fa-angle-down"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className="hero"
                  style={{
                    backgroundImage: `
                url("${
                  props.contentDataData[0].children[0] &&
                  props.contentDataData[0].children[0].children &&
                  props.contentDataData[0].children[0].children[0] &&
                  props.contentDataData[0].children[0].children[0][0] &&
                  props.contentDataData[0].children[0].children[0][0].value
                    ? returnResizedImageURL(
                        { w: 1900 },
                        props.contentDataData[0].children[0].children[0][0]
                          .value,
                      )
                    : '/banner.jpg'
                }")`,
                  }}
                >
                  {props.pluginOptions.heroVideoURL && (
                    <video
                      id="hero_video_player"
                      className="hero__video"
                      loop
                      muted
                      preload="metadata"
                      autoPlay
                      playsInline
                    >
                      <source
                        className="video__source"
                        type="video/mp4"
                        data-src={props.pluginOptions.heroVideoURL}
                      />
                      {props.pluginOptions.heroVideoURL_OGG && (
                        <source
                          className="video__source"
                          data-src={props.pluginOptions.heroVideoURL_OGG}
                          type="application/ogg"
                        />
                      )}
                      {props.pluginOptions.heroVideoURL_WEBM && (
                        <source
                          className="video__source"
                          data-src={props.pluginOptions.heroVideoURL_WEBM}
                          type="video/webm"
                        />
                      )}
                    </video>
                  )}
                </div>
              </div>
            )}
            <div className="bannerWrapper">
              <div className="container">
                <div className="row">
                  <div className="bannerContainer">
                    <div className="bannerText hero_bannerText">
                      {props.contentDataData[0] &&
                        props.contentDataData[0].children[1] &&
                        props.contentDataData[0].children[1].value && (
                          <h1>{props.contentDataData[0].children[1].value}</h1>
                        )}
                      {props.contentDataData[0] &&
                        props.contentDataData[0].children[2] &&
                        props.contentDataData[0].children[2].value && (
                          <h2>{props.contentDataData[0].children[2].value}</h2>
                        )}
                      {props.contentDataData[0] &&
                        props.contentDataData[0].children[3] &&
                        props.contentDataData[0].children[3].value && (
                          <WigLoader
                            weducWebsitesID={props.config.weducWebsitesID}
                            weducContainer={props.config.weducContainerID}
                            weducEntity={props.config.weducEntityID}
                            contentDataData={props.contentDataData}
                            contentMetaData={props.contentMetaData}
                            contentDataArray="0"
                            contentDataChild="3"
                            AccroPressProdEndpoint={props.config.apiEndpoint}
                            AccroPressLocalEndpoint={props.config.apiEndpoint}
                            integratorID={props.config.integratorID}
                            integratorAccessToken={
                              props.config.integratorAccessToken
                            }
                            imageFormatting={
                              process.env.IMAGE_FORMATTING_SECRET ||
                              process.env.GATSBY_IMAGE_FORMATTING_SECRET
                            }
                          />
                        )}
                    </div>
                    {!props.pluginOptions.hideNotices && (
                      <div
                        className={`banner-inner weducWidgetDisplay_${props.noticesMainLoaded}`}
                      >
                        <div
                          className={
                            props.noticesBannerExpanded ? 'close-alert' : 'open'
                          }
                        >
                          <div className="bannerTextClose">
                            <button onClick={() => props.toggleNoticesBanner()}>
                              <span>
                                {props.noticesBannerExpanded ? '' : ''}
                              </span>
                            </button>
                          </div>
                          <div className="bannerText">
                            <WeducNotices
                              delay={5}
                              limit={1}
                              name={props.config.weducWebsitesID}
                              container={props.config.weducEntityID}
                              html={props.NoticeTemplate}
                              loadedEvent={props.setNoticesMainLoaded}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default HeroInnerContent;
